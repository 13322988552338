import React, { useState } from 'react';
import CustomContainer from 'components/CustomContainer';
import { useTranslation } from 'react-i18next';
import DireksiDanny from 'images/AboutUs/direksi-danny.png';
import DireksiCath from 'images/AboutUs/direksi-cath.jpg';
import DireksiBryan from 'images/AboutUs/direksi-bryan.png';
import DireksiMandy from 'images/AboutUs/direksi-mandy.png';
import DireksiPutra from 'images/AboutUs/direksi-putra.jpg';
import KomisarisChandra from 'images/AboutUs/komisaris-chandra.png';
import KomisarisEunike from 'images/AboutUs/komisaris-eunike.png';
import KomisarisUma from 'images/AboutUs/komisaris-uma.png';
import KomisarisPamela from 'images/AboutUs/komisaris-pamela.png';
import KomisarisKevin from 'images/AboutUs/komisaris-kevin.png';
import KomisarisAdikin from 'images/AboutUs/komisaris-adikin.png';
import Close from 'images/AboutUs/close-icon.svg';
import ReactHtmlParser from 'react-html-parser';
import structureEN from 'images/investorRelations/structure-en.png';
import structureID from 'images/investorRelations/structure-id.png';
import { useLocation } from '@reach/router';

const teamData = [
  {
    id: 1,
    imageSrc: KomisarisChandra,
    title: 'Chandra',
  },
  {
    id: 6,
    imageSrc: KomisarisKevin,
    title: 'Kevin Cahaya',
  },
  {
    id: 7,
    imageSrc: DireksiDanny,
    title: 'Danny Sutradewa',
  },
  {
    id: 8,
    imageSrc: DireksiPutra,
    title: 'Andika Sutoro Putra',
  },
  {
    id: 9,
    imageSrc: DireksiMandy,
    title: 'Mandy P. Hartono',
  },
  {
    id: 11,
    imageSrc: DireksiCath,
    title: 'Katherine',
  },
];

const DetailsModal = ({ id, setModalIsOpen }) => {
  const { t } = useTranslation('about-us');

  return (
    <div
      class="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="fixed inset-0 bg-black bg-opacity-80 transition-opacity"></div>
      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-[#141414] text-left shadow-xl transition-all sm:my-8 w-[90%] sm:w-[80%] lg:w-[70%] h-min-[500px] sm:h-min-[400px] lg:h-min-[500px]">
            {teamData.map((person) => {
              if (person.id === id) {
                return (
                  <div className="flex justify-start items-center flex-col sm:flex-row h-full w-full p-7 sm:p-10 lg:p-14 xl:p-20 mt-14 sm:mt-0">
                    <img
                      src={person.imageSrc}
                      alt="struktur-team"
                      className="h-[220px] lg:h-[330px] w-auto rounded-lg object-contain"
                    />
                    <div className="sm:pl-7 lg:pl-10 pt-6 sm:pt-0">
                      <p className="font-medium sm:mb-2 text-2xl lg:text-4xl">
                        {person.title}
                      </p>
                      <p className="mb-6 lg:mb-8 text-lg lg:text-xl">
                        {t(`title ${person.title}`)}
                      </p>
                      <p className="text-xs lg:text-sm leading-relaxed font-extralight">
                        {ReactHtmlParser(t(`${person.title}`))}
                      </p>
                    </div>
                  </div>
                );
              }
            })}
            <img
              onClick={() => setModalIsOpen(false)}
              className="hover:brightness-200 cursor-pointer"
              src={Close}
              alt="close"
              style={{
                position: 'absolute',
                top: '25px',
                right: '25px',
                width: '32px',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Card = ({ id, imageSrc, title, description }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      {modalIsOpen && <DetailsModal id={id} setModalIsOpen={setModalIsOpen} />}
      <div
        className="flex flex-col cursor-pointer"
        onClick={() => {
          setModalIsOpen(true);
        }}
      >
        <img
          src={imageSrc}
          alt="struktur-team"
          className="h-[220px] sm:h-[400px] lg:h-[350px] rounded-lg object-cover mb-3 hover:opacity-80 transition-200"
        />
        <div className="text-base sm:text-2xl font-semibold">{title}</div>
        <div className="text-xs sm:text-base">{description}</div>
      </div>
    </>
  );
};

const SectionTeam = () => {
  const { t } = useTranslation('about-us');
  const loc = useLocation();
  const currentPath = loc.href;
  const isEnglish = currentPath.includes('/en/');

  return (
    <CustomContainer>
      <div
        className="flex flex-col py-24 justify-center items-center"
        id="organization-folk"
      >
        <div className="text-3xl sm:text-4xl text-center font-bold mb-10">
          {t('strukturOrganisasi')}
        </div>
        <img
          src={isEnglish ? structureEN : structureID}
          alt="structure"
          className="w-[80%] mb-10 lg:mb-20"
        />
        <div className="text-xl text-center sm:text-3xl font-medium mb-10 lg:mb-20 uppercase">
          {t('dewanKomisaris')}
        </div>
        <div className="grid grid-cols-[repeat(2,_1fr)] lg:grid-cols-[repeat(2,_1fr)] gap-5 sm:gap-10 xl:gap-10 mb-24">
          {teamData.slice(0, 2).map((person) => (
            <Card
              id={person.id}
              imageSrc={person.imageSrc}
              title={person.title}
              description={t(`title ${person.title}`)}
            />
          ))}
        </div>
        <div className="text-xl text-center sm:text-3xl font-medium mb-10 lg:mb-20 uppercase">
          {t('dewanDireksi')}
        </div>
        <div className="grid grid-cols-[repeat(2,_1fr)] lg:grid-cols-[repeat(4,_1fr)] gap-5 sm:gap-10 xl:gap-10 mb-20">
          {teamData.slice(2).map((person) => (
            <Card
              id={person.id}
              imageSrc={person.imageSrc}
              title={person.title}
              description={t(`title ${person.title}`)}
            />
          ))}
        </div>
      </div>
    </CustomContainer>
  );
};

export default SectionTeam;
