import React from 'react';
import CustomContainer from 'components/CustomContainer';
import { useTranslation } from 'react-i18next';
import fullTeam from '../../../images/AboutUs/fullteam.png';
import elementEarth from '../../../images/homepage/element-earth.png';

const SectionAbout = () => {
  const { t } = useTranslation('about-us');

  return (
    <div className="relative z-[10]">
      <img
        src={elementEarth}
        className="absolute top-0 right-0 2xl:w-[500px] xl:w-[450px] lg:w-[400px] w-[300px]"
        style={{ pointerEvents: 'none' }}
      />
      <CustomContainer>
        <div className="flex flex-col-reverse lg:flex-row w-full h-full items-center pt-24 sm:pt-32 xl:pt-44 pb-14 xl:pb-10">
          <div className="lg:w-[45%] pt-10 lg:pt-0 lg:pr-10 xl:pr-14 text-base">
            {t('about1')}
            <br />
            <br />
            {t('about2')}
            <br />
            <br />
            <b>FOLK Group</b>
            <br />
            Cultivating The Next Gen
          </div>
          <div className="lg:w-[55%] z-10">
            <img
              src={fullTeam}
              alt="full-team"
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          </div>
        </div>
      </CustomContainer>
    </div>
  );
};

export default SectionAbout;
