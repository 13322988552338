import React from 'react';
import CustomContainer from 'components/CustomContainer';
import TentangKamiBanner from 'images/AboutUs/tentang-kami-banner.png';
import arrowDownIc from 'images/icon/arrow-bottom-ic.svg';
import OrangeVector from 'images/icon/logo/folk-logo-half-orange.svg';
import { useTranslation } from 'react-i18next';

const SectionBanner = ({ bottomRef }) => {
  const scrollDown = () => {
    console.log('clicked');
    if (bottomRef.current) {
      window.scrollTo({
        top: bottomRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const { t } = useTranslation('about-us');

  return (
    <div id="about-folk" className="h-screen relative">
      <CustomContainer>
        <div className="pt-20 h-screen">
          <div className="absolute h-full w-full top-0 left-0">
            <img
              src={TentangKamiBanner}
              alt="background"
              className=" object-cover h-full w-full"
            />
          </div>
          <div className="z-10 absolute bottom-0 right-0">
            <img
              src={OrangeVector}
              alt="orange-vector"
              className="object-contain h-auto xl:w-[500px] md:w-[400px] w-[230px] z-10"
            />
          </div>
          <div className="h-full flex flex-col justify-center">
            <h1 className="text-4xl md:text-6xl font-medium sm:mb-3 z-10">
              {t('sekilas')}
            </h1>
            <h1 className="text-4xl md:text-6xl font-medium mb-3 sm:mb-5 z-10">
              {t('tentangFolk')}
            </h1>
            <p className="text-sm md:text-base w-[100%] sm:w-[70%] lg:w-[45%] font-extralight mb-10 z-10">
              {t('description')}
            </p>
            <img
              onClick={scrollDown}
              src={arrowDownIc}
              alt="icon-arrow"
              className="cursor-pointer hidden md:block z-10 w-[25px] cursor-pointer"
            />
          </div>
        </div>
      </CustomContainer>
    </div>
  );
};

export default SectionBanner;
