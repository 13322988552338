import React, { useLayoutEffect, useRef } from 'react';
import SectionBanner from './SectionBanner';
import SectionAbout from './SectionAbout';
import SectionVisionMission from './SectionVisionMission';
import SectionStrategicFramework from './SectionStrategicFramework';
import SectionTeam from './SectionTeam';
import SectionTigaPilar from 'components/pages/Homepage/SectionTigaPilar';
import Glow from 'images/AboutUs/glow.svg';
import { useLocation } from '@reach/router';

const AboutUs = () => {
  const toBottomRef = useRef(null);
  const loc = useLocation();

  useLayoutEffect(() => {
    if (loc.hash) {
      const target = loc.hash.replace(/#/g, '');
      const el = document.getElementById(target);
      const top = window.scrollY + el.getBoundingClientRect().top;
      window.scrollTo({ top, behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="bg-black z-[-10]">
      <img
        src={Glow}
        style={{
          width: '100vw',
          position: 'absolute',
          top: '100vh',
          zIndex: 1,
        }}
      />
      <div className="text-white">
        <SectionBanner bottomRef={toBottomRef} />
        <SectionAbout />
        <SectionVisionMission bottomRef={toBottomRef} />
        <SectionTigaPilar
          id="ecosystem-folk"
          isAboutUs
          hideBgColor
          containerClassName="py-24"
        />
        <SectionStrategicFramework />
        <SectionTeam />
      </div>
    </div>
  );
};

export default AboutUs;
