import React from 'react';
import OrangeVector from 'images/icon/logo/folk-logo-half-orange.svg';
import CustomContainer from 'components/CustomContainer';
import { useTranslation } from 'react-i18next';

const SectionVisionMission = ({ bottomRef }) => {
  const { t } = useTranslation('about-us');

  return (
    <CustomContainer>
      <div
        ref={bottomRef}
        id="vision-and-mission"
        className="flex flex-col lg:flex-row items-center justify-center h-full py-24 lg:py-44"
      >
        <div className="flex flex-col lg:w-[50%] lg:h-[250px] lg:justify-start mb-16 lg:mb-0 z-[10]">
          <div className="flex flex-row lg:w-[85%] items-end mb-5">
            <img
              src={OrangeVector}
              className="w-[30px] sm:w-[50px] lg:w-[90px] h-full object-contain"
              alt="orange-vector"
            />
            <div className="text-3xl md:text-4xl xl:text-5xl xl:text-6xl font-semibold ml-5 lg:ml-10">
              {t('visi')}
            </div>
          </div>
          <div
            className="lg:font-medium text-lg sm:text-xl xl:text-2xl lg:w-[85%]"
            style={{ lineHeight: 1.6 }}
          >
            {t('descriptionVisi')}
          </div>
        </div>
        <div className="flex flex-col lg:w-[50%] lg:items-center lg:h-[250px] lg:justify-start z-[10]">
          <div className="flex flex-row lg:w-[85%] items-end mb-5">
            <img
              src={OrangeVector}
              className="w-[30px] sm:w-[50px] lg:w-[90px] h-full object-contain"
              alt="orange-vector"
            />
            <div className="text-3xl md:text-4xl xl:text-5xl xl:text-6xl font-semibold ml-5 lg:ml-10">
              {t('misi')}
            </div>
          </div>
          <div
            className="lg:font-medium text-lg sm:text-xl xl:text-2xl lg:w-[85%]"
            style={{ lineHeight: 1.6 }}
          >
            {t('descriptionMisi')}
          </div>
        </div>
      </div>
    </CustomContainer>
  );
};

export default SectionVisionMission;
