import React from "react";
import Pages from "../components/Pages";
import AboutUs from "../components/pages/AboutUs";
import { graphql } from "gatsby";

const AboutUsPage = () => {
  return (
    <Pages title="FOLK - About Us">
      <AboutUs />
    </Pages>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default AboutUsPage;
