import React from 'react';
import cultivatingDiagram from 'images/AboutUs/cultivating-diagram.png';
import CustomContainer from 'components/CustomContainer';
import { useTranslation } from 'react-i18next';
import total1 from 'images/AboutUs/total1.png';
import total2 from 'images/AboutUs/total2.png';
import total4 from 'images/AboutUs/total4.png';
import total5 from 'images/AboutUs/total5.png';
import ReactHtmlParser from 'react-html-parser';

const DescriptionBox = ({ label, title, description }) => {
  return (
    <div className="flex flex-row justify-start items-center px-5 sm:px-8 h-[160px] sm:h-[200px] bg-[#151515] rounded-lg">
      <div className="text-4xl sm:text-5xl xl:text-7xl font-bold pr-5 sm:pr-8">
        {label}
      </div>
      <div className="flex flex-col">
        <div className="text-md sm:text-xl font-semibold mb-2">{title}</div>
        <div className="text-xs sm:text-sm xl:text-base font-light">
          {description}
        </div>
      </div>
    </div>
  );
};

const SectionStrategicFramework = () => {
  const { t } = useTranslation('about-us');

  return (
    <CustomContainer>
      <div id="framework-folk" className="flex flex-col py-24">
        <div className="text-3xl sm:text-4xl font-bold mb-10 sm:mb-16 text-center lg:text-start z-[10]">
          {t('cultivatingTheNext')}
        </div>
        <div className="flex flex-col lg:flex-row items-center lg:items-start w-full mb-28 sm:mb-32 z-[10]">
          <img
            src={cultivatingDiagram}
            alt="strategic-framework-diagram"
            className="w-[85%] sm:w-[65%] lg:w-[43%] object-contain mb-10 lg:mb-0"
          />
          <div className="lg:w-[57%] lg:ml-16">
            <p className="font-light text-base sm:text-[22px] leading-relaxed mb-10">
              {ReactHtmlParser(t('cultivatingDesc'))}
            </p>
            <div className="grid grid-cols-[repeat(2,_1fr)] sm:grid-cols-[repeat(2,_1fr)]">
              <img
                src={total1}
                alt="total1"
                style={{
                  objectFit: 'contain',
                  height: '70%',
                  width: 'fit-content',
                }}
              />
              <img
                src={total2}
                alt="total2"
                style={{
                  objectFit: 'contain',
                  height: '70%',
                  width: 'fit-content',
                }}
              />
              <img
                src={total4}
                alt="total4"
                style={{
                  objectFit: 'contain',
                  height: '70%',
                  width: 'fit-content',
                }}
              />
              <img
                src={total5}
                alt="total5"
                style={{
                  objectFit: 'contain',
                  height: '70%',
                  width: 'fit-content',
                }}
              />
            </div>
          </div>
        </div>
        <div className="text-3xl sm:text-4xl font-bold mb-10 sm:mb-16 text-center lg:text-start z-[10]">
          {t('strategicFramework')}
        </div>
        <div className="grid grid-cols-[repeat4,_1fr] lg:grid-cols-[repeat(2,_1fr)] items-center gap-5 sm:gap-7 xl:gap-10 z-[10]">
          <DescriptionBox
            label="A"
            title={t('titleA')}
            description={t('descriptionA')}
          />
          <DescriptionBox
            label="B"
            title={t('titleB')}
            description={t('descriptionB')}
          />
          <DescriptionBox
            label="C"
            title={t('titleC')}
            description={t('descriptionC')}
          />
          <DescriptionBox
            label="D"
            title={t('titleD')}
            description={t('descriptionD')}
          />
        </div>
      </div>
    </CustomContainer>
  );
};

export default SectionStrategicFramework;
